
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-gender-equity-report',
  components: {
    Datatable,
    Multiselect,
  },
  data() {
    return {
      batch: {
        tranche_id: [] as any,
        entity_type: '',
        start_date: '',
        end_date: '',
      },
      optionsTranche: [] as any,
      TrancheInfos: [] as any,
      tranches_info: [] as any,
      api_url: '',
      total_institute: '' as any,
      total_course: '' as any,
      total_batch: '' as any,
      total_enroll_female_percent: '' as any,
      total_certification_female_percent: '' as any,
      total_female_in_female_certified: '' as any,
      total_placement_female_percent: '' as any,
      total_female_in_female_placement: '' as any,

      data: [] as any,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showTableData: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);
      let data = `${this.VUE_APP_API_URL}/api/report/gender-equity_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);
      await ApiService.post('report/gender-equity_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/gender-equity_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Gender Equity Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
 async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    getTableData() {
      this.loading = true;
      ApiService.get(
        'report/gender-equity?tranche_id=' +
          this.batch.tranche_id +
          '&entity_type=' +
          this.batch.entity_type +
          '&start_date=' +
          this.batch.start_date +
          '&end_date=' +
          this.batch.end_date
      )
        .then((response) => {
          this.loading = false;
          this.data = response.data.data.entitydata;
          const sum = this.data.reduce(
            (acc, cur) => {
              acc.sub_institute_count += cur.sub_institute_count;
              acc.sub_batch_count += cur.sub_batch_count;
              acc.sub_course_count += cur.sub_course_count;

              acc.sub_employment_female_count +=
                cur.sub_employment_female_count;
              acc.sub_enroll_female_count += cur.sub_enroll_female_count;
              acc.sub_certified_female_count += cur.sub_certified_female_count;
              acc.sub_female_in_certified_female_count +=
                cur.sub_female_in_certified_female_count;
              acc.sub_female_in_employment_female_count +=
                cur.sub_female_in_employment_female_count;

              acc.sub_total_trainee += cur.sub_total_trainee;
              acc.sub_total_certified_trainee +=
                cur.sub_total_certified_trainee;
              acc.sub_total_placement_trainee +=
                cur.sub_total_placement_trainee;
              return acc;
            },
            {
              sub_total_trainee: 0,
              sub_total_certified_trainee: 0,
              sub_total_placement_trainee: 0,
              sub_institute_count: 0,
              sub_batch_count: 0,
              sub_course_count: 0,
              sub_employment_female_count: 0,
              sub_enroll_female_count: 0,
              sub_certified_female_count: 0,
              sub_female_in_certified_female_count: 0,
              sub_female_in_employment_female_count: 0,
            }
          );

          this.total_institute = sum.sub_institute_count;
          this.total_course = sum.sub_course_count;
          this.total_batch = sum.sub_batch_count;

          this.total_enroll_female_percent = sum.sub_total_trainee
            ? (
                (sum.sub_enroll_female_count / sum.sub_total_trainee) *
                100
              ).toFixed(2)
            : 0;
          // change

          this.total_certification_female_percent =
            sum.sub_total_certified_trainee
              ? (
                  (sum.sub_certified_female_count /
                    sum.sub_total_certified_trainee) *
                  100
                ).toFixed(2)
              : 0;
          this.total_placement_female_percent = sum.sub_total_placement_trainee
            ? (
                (sum.sub_employment_female_count /
                  sum.sub_total_placement_trainee) *
                100
              ).toFixed(2)
            : 0;
          this.total_female_in_female_certified = sum.sub_enroll_female_count
            ? (
                (sum.sub_certified_female_count / sum.sub_enroll_female_count) *
                100
              ).toFixed(2)
            : 0;
          this.total_female_in_female_placement = sum.sub_enroll_female_count
            ? (
                (sum.sub_employment_female_count /
                  sum.sub_enroll_female_count) *
                100
              ).toFixed(2)
            : 0;

          // this.total_institute = response.data.data.total_institute;
          // this.total_course = response.data.data.total_course;
          // this.total_batch = response.data.data.total_batch;
          // this.total_enroll_female_percent =
          //   response.data.data.total_enroll_female_percent;
          // this.total_certification_female_percent =
          //   response.data.data.total_certification_female_percent;
          // this.total_female_in_female_certified =
          //   response.data.data.total_female_in_female_certified;
          // this.total_placement_female_percent =
          //   response.data.data.total_placement_female_percent;
          // this.total_female_in_female_placement =
          //   response.data.data.total_female_in_female_placement;
          this.showTableData = true;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
  setup() {},
});
